<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSaveForm()"
    @cancel="onCancel('SupportReqTemplates')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'SupportReqTemplateCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      controllerName: 'SupportReqTemplates',
      pageHeader: 'Создание шаблона заявок',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Шаблоны заявок',
          route: { name: 'SupportReqTemplates' },
        },
        {
          text: 'Создание шаблона заявок',
        },
      ],
      isLoadingPage: false,
      initialForm: {
        accountId: null,
        customerId: null,
        applicationName: null,
        text: null,
        typeId: null,
        priorityId: null,
        periodicity: null,
        dayWeek: null,
        dayMounth: null,
        time: null,
        displayId: null,
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      customers: (state) => state.common.customers,
      customerAccounts: (state) => state.common.customerAccounts,
      types: (state) => state.common.types,
      priorities: (state) => state.common.priorities,
      periods: (state) => state.common.periods,
      dayWeeks: (state) => state.common.dayWeeks,
      employeeAccounts: (state) => state.common.employeeAccounts,
    }),

    fields() {
      const { periodicity } = this.form;
      const form = [
        { type: 'text', key: 'applicationName', label: 'Наименование' },
        { type: 'textarea', key: 'text', label: 'Текст' },
        {
          type: 'select',
          key: 'customerId',
          label: 'Клиент',
          list: this.customers.data || [],
        },
        {
          type: 'select',
          key: 'accountId',
          label: 'Аккаунт',
          list: this.customerAccounts.data || [],
        },
        {
          type: 'select',
          key: 'typeId',
          label: 'Тип заявки',
          list: this.types.data || [],
        },
        {
          type: 'select',
          key: 'priorityId',
          label: 'Приоритет заявки',
          list: this.priorities.data || [],
        },
        {
          type: 'select',
          key: 'periodicity',
          label: 'Периодичность',
          list: this.periods.data || [],
        },
        {
          type: 'select',
          key: 'dayWeek',
          label: 'День недели',
          list: this.dayWeeks.data || [],
          attributes: {
            clearable: true,
          },
        },
        { type: 'number', key: 'dayMounth', label: 'Число месяца' },
        { type: 'time', key: 'time', label: 'Время' },
        {
          type: 'select',
          key: 'displayId',
          label: 'Автопринятие сотрудником',
          list: this.employeeAccounts.data || [],
        },
      ];

      if (periodicity == 'н') {
        const hideFieldIndex = form.findIndex(
          (field) => field.key === 'dayMounth'
        );
        form.splice(hideFieldIndex, 1);
      }

      if (periodicity == 'м' || periodicity == 'г') {
        const hideFieldIndex = form.findIndex(
          (field) => field.key === 'dayWeek'
        );
        form.splice(hideFieldIndex, 1);
      }

      return [
        {
          form,
        },
      ];
    },
  },

  watch: {
    'form.customerId': {
      handler(newVal) {
        if (newVal) {
          this.getCommonList({
            name: 'CustomerAccounts',
            params: { customerId: this.form.customerId },
          });
        }
      },
    },

    'form.periodicity'() {
      this.validateForm();
    },
  },

  created() {
    this.isLoadingPage = true;
    const customers = this.getCommonList({ name: 'Customers' });
    const types = this.getCommonList({ name: 'Types' });
    const priorities = this.getCommonList({ name: 'Priorities' });
    const periods = this.getCommonList({ name: 'Periods' });
    const dayWeeks = this.getCommonList({ name: 'DayWeeks' });
    const employeeAccounts = this.getCommonList({ name: 'EmployeeAccounts' });

    Promise.all([
      customers,
      types,
      priorities,
      periods,
      dayWeeks,
      employeeAccounts,
    ]).then(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),

    onSaveForm() {
      const { dayMounth, dayWeek } = this.form;

      if (!dayWeek) this.$set(this.form, 'dayWeek', null);
      if (dayMounth == 1) this.$set(this.form, 'dayMounth', 1);

      this.onSave('SupportReqTemplates');
    },

    validateForm() {
      const { periodicity, dayMounth, dayWeek } = this.form;

      if ((periodicity == 'м' || periodicity == 'г') && dayWeek !== null) {
        this.$set(this.form, 'dayWeek', null);
      }

      if (periodicity == 'н' && dayMounth != 1) {
        this.$set(this.form, 'dayMounth', 1);
      }
    },
  },
};
</script>
